import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация и CRM для кофейни&nbsp;</span>
	),
	price: "от 2490 ₽/мес",
	subTitle:
		"Quick Resto — система автоматизации кофеен. Больше возможностей для привлечения гостей и повышения среднего чека. Приём заказов на кассе, расчёт гостей и выдача чека, контроль работы персонала, учёт складских операций и аналитика продаж.",
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/coffee-presentation.png"
			alt="автоматизация кофейни"
			breakpoints={[440, 740, 920, 560]}
			sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 560px"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
	mobileCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
